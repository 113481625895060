<template>
    <OModal ref="modal" @hidden="clearAllFields">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Add New Person') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="form-group col-12 col-lg-6">
                            <label>{{ $t('First name') }} *</label>
                            <input class="form-control" v-model="personName" />
                        </div>
                        <div class="form-group col-12 col-lg-6">
                            <label>{{ $t('Last name') }} *</label>
                            <input class="form-control" v-model="personSurname" />
                        </div>
                        <div class="form-group col-12 col-lg-6">
                            <label>{{ $t('Org Unit') }} *</label>
                            <OOrgunitsLookup value="" allowClear :restrictToContext="false" class="form-control" textInput :modelValue="orgUnit.name" :bind="sel=>{orgUnit.name=sel.Name;orgUnit.id=sel.ID}"/>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" :disabled="isCreateButtonDisabled" @click="createNewPerson">
                        <div v-if="isLoading" class="spinner-border spinner-border-sm" style="0.35rem" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                        {{ $t("Add New Person") }}
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("Cancel") }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { ref, defineExpose, computed  } from 'vue';
    import { getOrCreateProcedure } from 'o365.vue.ts';
    import OOrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue';
    import context from 'o365.modules.Context.ts';

    const procAddNewPerson = getOrCreateProcedure(
        {
            id: 'procAddNewPerson' + crypto.randomUUID(), 
            procedureName: 'sstp_System_PersonsCreate'
        }
    );
    const emit = defineEmits(["PersonCreated"]);

    const modal = ref(null);
    const isLoading = ref(false);
    const personName = ref(null);
    const personSurname = ref(null);
    const orgUnit = ref({id:context.id, name:context.name})

    const openModal = () => {
        modal.value.show();
    };
    const hideModal = () =>{
        modal.value.hide();
    }
    defineExpose({ openModal, hideModal });

    const isCreateButtonDisabled = computed(() => {
      return isLoading.value || !requiredFieldsFilled()
    });

    function createNewPerson(){
        isLoading.value = true;
        procAddNewPerson.execute({
            FirstName: personName.value,
            LastName: personSurname.value,
            OrgUnit_ID: orgUnit.value.id,
            Local: true
        }).then((pData)=>{
            emit("PersonCreated", pData.Table[0].ID)
            isLoading.value = false;
        }).catch(()=>{
            isLoading.value = false;
        })
    }

    function clearAllFields(){
        personName.value = null;
        personSurname.value = null;
        orgUnit.value.id = null;
        orgUnit.value.name = null;
    }

    function requiredFieldsFilled(){
        return personName.value && personSurname.value && orgUnit.value.id
    }
</script>